<template>
    <el-dialog
      :title="modalType === 'edit' ? '修改押金' : '添加押金设置'"
      :close-on-click-modal="isClickModalClose"
      v-model="isShow"
      :width="defaultWidth"
      @close="closeModal"
    >
      <el-form
        :ref="formName"
        :model="modalData"
        :rules="ruleValidate"
        label-width="110px"
      >
      <el-form-item label="电池编号：" prop="batteryMacId">
          <span>{{ modalData.batteryMacId }}</span>
        </el-form-item>
  
        <el-form-item label="用户" prop="userId">
          <eb-filter-user-list  v-model="modalData.userId"></eb-filter-user-list>
        </el-form-item>
      </el-form>
  
      <template #footer>
        <el-button @click="closeModal"> 取消 </el-button>
  
        <el-button @click="submit" type="primary"> 确定 </el-button>
      </template>
    </el-dialog>
  </template>
  
  <script>
  import modalMixin from "@/mixins/modalMixin";
  import { mapState } from "vuex";
  
  export default {
    computed: mapState({
     
    }),
  
    mixins: [modalMixin],
    emits: ["submit"],
    props: ["modalData", "modalType"],
    data() {
      return {
        formName: "form",
        ruleValidate: {
          userId: [
            {
              required: true,
              message: "用户不能为空",
              trigger: "change",
            },
          ],
        },
      };
    },
    methods: {
  
      submit() {
        this.validateForm().then((res) => {
          let obj = {
            userId: this.modalData.userId,
            batteryMacId: this.modalData.batteryMacId
          };
          this.$emit("submit", obj);
        });
      },
    },
  };
  </script>
  